<template>
  <div class="backQ"> 
    <h2>腰背Keele STarT背部筛查问卷</h2>
    <div v-if="steps == 0">           
          <van-form @submit="nextStep">            
            <p class="question">1. 您是否有明显的下肢放射痛?</p>
            <van-radio-group v-model="backQData.screeningQ1" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>

            <p class="question">2. 您是否走不了多远（小于200米）就需要停下来休息才能继续行走?</p>
            <van-radio-group v-model="backQData.screeningQ2" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>

            <p class="question">3. 您是否有大小便控制不住的情况?</p>
            <van-radio-group v-model="backQData.screeningQ3" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>

            <p class="question">4.您走路是否有不稳、脚踩棉花的感觉?</p>
            <van-radio-group v-model="backQData.screeningQ4" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>
           
            <van-button block type="info" native-type="submit">下一步</van-button>
          </van-form>          
        </div>
        <div v-if="steps == 1">         
          <h2>根据您过去两周的情况，回答一下问题：</h2>
          <van-form @submit="thirdStep">            
            <p class="question">1.在过去的两周里，我的背部疼痛往腿部放射。</p>
            <van-radio-group v-model="backQData.q1" direction="horizontal">
              <van-radio :name="true">同意</van-radio>
              <van-radio :name="false">不同意</van-radio>
            </van-radio-group>

            <p class="question">2.在过去的两周里，我的肩膀或颈部都有疼痛。</p>
            <van-radio-group v-model="backQData.q2" direction="horizontal">
              <van-radio :name="true">同意</van-radio>
              <van-radio :name="false">不同意</van-radio>
            </van-radio-group>

            <p class="question">3.因为背部疼痛，我只能走比较短的距离。</p>
            <van-radio-group v-model="backQData.q3" direction="horizontal">
              <van-radio :name="true">同意</van-radio>
              <van-radio :name="false">不同意</van-radio>
            </van-radio-group>

            <p class="question">4.在过去的两周里，因为背痛，我穿衣服比平时慢了。</p>
            <van-radio-group v-model="backQData.q4" direction="horizontal">
              <van-radio :name="true">同意</van-radio>
              <van-radio :name="false">不同意</van-radio>
            </van-radio-group>

            <p class="question">5.以我目前的情况，进行运动锻炼并不安全。</p>
            <van-radio-group v-model="backQData.q5" direction="horizontal">
              <van-radio :name="true">同意</van-radio>
              <van-radio :name="false">不同意</van-radio>
            </van-radio-group>

            <p class="question">6.我经常为了腰背部疼痛感到担忧。</p>
            <van-radio-group v-model="backQData.q6" direction="horizontal">
              <van-radio :name="true">同意</van-radio>
              <van-radio :name="false">不同意</van-radio>
            </van-radio-group>

            <p class="question">7.我感觉我的背痛很严重，而且永远都不会好转了。</p>
            <van-radio-group v-model="backQData.q7" direction="horizontal">
              <van-radio :name="true">同意</van-radio>
              <van-radio :name="false">不同意</van-radio>
            </van-radio-group>

            <p class="question">8.总的来说，腰背部疼痛导致我的生活质量明显下降。</p>
            <van-radio-group v-model="backQData.q8" direction="horizontal">
              <van-radio :name="true">同意</van-radio>
              <van-radio :name="false">不同意</van-radio>
            </van-radio-group>

            <p class="question">9.总体上，过去两周您的背痛对您有多大困扰?</p>
            <div class="slider-wrap">
              <van-slider v-model="backQData.q9" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>

            <van-button block type="info" native-type="submit">提交</van-button>
          </van-form>
        </div>
  </div>
</template>
<script>
import { addBackQ } from "@/api/getData"
import { Toast } from "vant"

export default {
  name: "backQ",
  data() {
    return {
      steps:0,
      qName:'',
      backQData:{
        userId: '',
        screeningQ1 : '',// '您是否有明显的下肢放射痛',
        screeningQ2 : '',// '您是否走不了多远（小于200米）就需要停下来休息才能继续行走？',
        screeningQ3 : '',// '您是否有大小便控制不住的情况',
        screeningQ4 : '',// '您走路是否有不稳、脚踩棉花的感觉',
        q1:"",
        q2:"",
        q3:"",
        q4:"",
        q5:"",
        q6:"",
        q7:"",
        q8:"",
        q9: 0,
      }      
    }
  },
  methods: {   
    //下一步
    nextStep(){
      if (!this.backQData.screeningQ1 || !this.backQData.screeningQ2 || !this.backQData.screeningQ3 || !this.backQData.screeningQ4) {
        return Toast('请填写全部问卷')
      }
      if(this.backQData.screeningQ1 == "true"
      || this.backQData.screeningQ2 == "true"
      || this.backQData.screeningQ3 == "true"
      || this.backQData.screeningQ4 == "true"
      ){
        this.$confirm('温馨提示：您可能存在比较明显的腰椎间盘突出、椎管狭窄或颈椎病的问题，建议到专业脊柱外科医生处就诊。', '提示', {
          confirmButtonText: '我知道了',
          type: 'warning',
          showCancelButton: false,
          confirmButtonClass: 'confirm-button',
          center: true
        }).then(() => {
          this.steps = 1       
        })
      }else{
        this.steps = 1
      }
      document.documentElement.scrollTop = document.body.scrollTop = 0  
    },
    //提交表单
    thirdStep(){
      for (var i = 1; i < 9; i++) {
        if (this.backQData['q' + i] === "") {
          return Toast('请填写全部问卷')
        }
      }
      addBackQ(this.backQData).then(res => {
        this.$message.success('已成功添加腰背训练方案!');
        this.$router.push({path: "/",query: { preId: res.data}})
      })        
    },
  },
  mounted() {
    this.backQData.userId = this.vUserId;
  }
}
</script>

<style lang="scss" scoped>
.backQ {
  padding: 0 .16rem;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  h2 {
    font-size: .18rem;
    font-weight: 500;
    line-height: 1;
    padding-top: 0.4rem;
    opacity: 0.9;
  }

  .van-form {
    font-size: 0.14rem;
    font-weight: 700;

    .question {
      line-height: 0.24rem;
      margin: 0.48rem 0 0.24rem;
      color: #3d3d3d;
    }

    :deep(.van-radio) {
      min-width: 0.61rem;
      height: 0.41rem;
      align-items: center;
      justify-content: center;
      border-radius: 0.04rem;

      .van-radio__icon {
        width: 0.16rem;
        height: 0.16rem;
      }

      .van-radio__label {
        margin-left: 0.1rem;
      }

      .van-radio__icon--checked .van-icon {
        background-color: #24bbd3 !important;
        border-color: #24bbd3 !important;
      }

      .van-radio__icon.van-radio__icon--checked + .van-radio__label {
        color: #24bbd3;
      }
    }

    .van-button {
      height: 0.48rem;
      margin: 0.64rem 0 0.32rem;
      border-radius: 0.4rem;
    }
  }
}
</style>